import React from "react";

function Basics({ basics }) {
  return (
    <div className="basics">
      <div>
        <div className="imgContainer">
          <img src={basics.image} alt="" />
        </div>
        <p className="basicsSummary">{basics.summary}</p>
      </div>
    </div>
  );
}

export default Basics;
