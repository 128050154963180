import { useEffect, useState } from "react";

const BREAKPOINT = 1200;

const useShowMenu = function () {
  const [showMenu, setShowMenu] = useState(window.innerWidth < BREAKPOINT);

  const handleResize = () => {
    setShowMenu(window.innerWidth < BREAKPOINT);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return showMenu;
};

export default useShowMenu;
