function BasicsHeader({ basicsHeader }) {
    return (
        <div>
            <div>
                <p className="nameStyle">{basicsHeader.name}</p>
                <p className="labelStyle">{basicsHeader.label}</p>
            </div>
        </div>
    );
}

export default BasicsHeader;