import { useEffect, useState } from "react";
import { useLanguage } from "../providers/language";

function useResume() {
  const { language } = useLanguage();
  const [resume, setResume] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch("resume-" + language + ".json")
      .then((response) => response.json())
      .then((resume) => setResume(resume))
      .finally(() => setLoading(false));
  }, [language]);

  return {
    resume,
    loading,
  };
}

export default useResume;
