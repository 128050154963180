import React from "react";

function Projects({ projects }) {
  return (
    <div className="projects">
      <div>
        {projects.map(function (project, index) {
          return (
            <div key={index}>
              <p className="bold name">{project.name}</p>
              <p className=" inline nameSub">
                <i>
                  {project.position}, {project.endDate}
                </i>
              </p>
              <p><br />{project.summary}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Projects;
