import React from "react";

function Languages({ languages }) {
  return (
    <div className="languages">
      <div>
        {languages.map(function (language, index) {
          return (
            <div key={index}>
              <p className="inline bold">{language.language}</p>
              <p className="inline">: {language.fluency}.</p>
            </div>
          );
        })}
      </div>
      <div className="line" />
    </div>
  );
}

export default Languages;
