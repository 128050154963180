import useResume from "./hooks/useResume";
import BasicsHeader from "./components/BasicsHeader";
import Basics from "./components/Basics";
import Works from "./components/Works";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Languages from "./components/Languages";
import Education from "./components/Education";
import Contact from "./components/Contact";
import Tabs from "./components/Tabs";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import { useState } from "react";
import useShowMenu from "./hooks/useShowMenu";

function App() {
  const { resume } = useResume();
  const showMenu = useShowMenu();

  const [section, setSection] = useState("home");

  function handleSection(newSection) {
    setSection(newSection);
  }

  if (!resume) {
    return null;
  }

  return (
    <div className="App">
      <div className="AppContainer">
        <BasicsHeader basicsHeader={resume.basics} />
        {showMenu ? (
          <Menu active={section} onChange={handleSection} />
        ) : (
          <Tabs active={section} onChange={handleSection} />
        )}
        {section === "home" && <Basics basics={resume.basics} />}
        {section === "work" && <Works works={resume.work} />}
        {section === "projects" && <Projects projects={resume.projects} />}
        {section === "skills" && <Skills skills={resume.skills} />}
        {section === "languages" && <Languages languages={resume.languages} />}
        {section === "education" && <Education education={resume.education} />}
        {section === "contact" && <Contact basics={resume.basics} />}
        <Footer basics={resume.basics} />
      </div>
    </div>
  );
}

export default App;
