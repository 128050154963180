import React from "react";

function Skills({ skills }) {
  return (
    <div className="skills">
      {skills.map(function (skill, index) {
        return (
          <div key={index}>
            <p className="inline bold ">{skill.name}: </p>
            <p className="inline"> {skill.keywords.join(", ")}.</p>
          </div>
        );
      })}
    </div>
  );
}

export default Skills;
