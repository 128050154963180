import { FaLinkedin, FaGithub } from "react-icons/fa";
import { MdMail } from "react-icons/md";

function ProfileLink({ item }) {
  const handleIcon = () => { window.open(item.url, "_blank") };
  return (
    <div className="ProfileLink" onClick={handleIcon}>
      {item.network === "LinkedIn" && <FaLinkedin size={40} />}
      {item.network === "Github" && <FaGithub size={40} />}
      {item.network === "Mail" && <MdMail size={40} />}
    </div>
  );
}

export default ProfileLink;

