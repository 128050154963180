import { useLanguage } from "../providers/language";

function LanguageSwitcher() {
  const { language, setLanguage } = useLanguage();

  return (
    <div className="LanguageSwitcher">
      <button
        className={"cursor " + (language === "en" ? "selected" : "")}
        onClick={() => setLanguage("en")}
      >
        EN
      </button>
      <p className="verticalLine inline">|</p>
      <button
        className={"cursor " + (language === "es" ? "selected" : "")}
        onClick={() => setLanguage("es")}
      >
        ES
      </button>
    </div>
  );
}

export default LanguageSwitcher;
