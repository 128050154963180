import React from "react";

function Education({ education }) {
  return (
    <div className="education">
      <div>
        {education.map(function (item, index) {
          return (
            <div key={index}>
              <p className="inline name bold">{item.institution}</p>
              <p className=" inline">
                , <span className="name inline bold">{item.location}</span> <span className="nameSub inline">| {item.endDate} </span><br />
              </p>
              <p>
                {item.studyType} in {item.area}.
              </p>
            </div>
          );
        })}
      </div>
      <div className="line" />
    </div>
  );
}

export default Education;
