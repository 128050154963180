import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormspark } from "@formspark/use-formspark";

const FORMSPARK_FORM_ID = "ewBJeW8Z";

function Contact({ basics }) {
  const { t } = useTranslation();
  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleName = (event) => {
    setName(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleMessage = (event) => {
    setMessage(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    await submit({
      name,
      email,
      message,
    });
    setName("");
    setEmail("");
    setMessage("");
    alert("{t('thanks')}");
  };

  return (
    <div className="contact">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <label htmlFor="name">{t('name')}</label>
          <input
            name="name"
            type="text"
            value={name}
            onChange={handleName}
            required
          />
        </div>
        <div className="row">
          <label htmlFor="email">{t('email')}</label>
          <input
            name="email"
            type="email"
            value={email}
            onChange={handleEmail}
            required
          />
        </div>
        <div className="row">
          <label htmlFor="message">{t('message')}</label>
          <textarea
            name="message"
            type="message"
            value={message}
            onChange={handleMessage}
            rows="4"
            required
          />
        </div>
        <div className="row">
          <label></label>
          <div className="submitButtonContainer">
            <button
              className="submitButton"
              type="submit"
              disabled={submitting}
            >
              {t('submit')}
            </button>
          </div>
        </div>
      </form>
      <div className="line" />
      <div>
        <p>
          {t('phone')}{" "}
          <a href={"tel:" + basics.phone} className="bold">
            {basics.phone}
          </a>
        </p>
      </div>
    </div>
  );
}

export default Contact;
