import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          'home': 'Home',
          'experience': 'Experience',
          'projects': 'Projects',
          'skills': 'Skills',
          'languages': 'Languages',
          'educacion': 'Education',
          'contact': 'Contact',
          'name': 'Name',
          'email': 'Email',
          'message': 'Message',
          'phone': 'To get in touch, phone',
          'thanks': 'Thanks for contacting me!',
          'submit': 'Submit',
          'menu': 'menu'
          ,
        }
      },
      es: {
        translation: {
          'home': 'Inicio',
          'experience': 'Experiencia',
          'projects': 'Proyectos',
          'skills': 'Habilidades',
          'languages': 'Lenguajes',
          'education': 'Educación',
          'contact': 'Contacto',
          'name': 'Nombre',
          'email': 'Correo',
          'message': 'Mensaje',
          'phone': 'Puede contactarme al',
          'thanks': 'Gracias por contactarme!',
          'submit': 'Enviar',
          'menu': 'menú'
        }
      }
    }
  });

export default i18n;