import React from "react";

function Works({ works }) {
  return (
    <div className="works">
      <div>
        {works.map(function (work, index) {
          return (
            <div key={index}>
              <p className="inline bold name">{work.name}</p>
              <p className="inline nameSub">
                , {work.mode} | <a href={work.url}>{work.url}</a>
              </p>
              <p className="nameSub">
                <i>
                  {work.position} , {work.startDate} to {work.endDate}
                </i>
              </p>
              <p>{work.summary}</p><br />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Works;
