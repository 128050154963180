import { useTranslation } from "react-i18next";

function Tabs({ active, onChange }) {
  const { t } = useTranslation();
  return (
    <div className="tabsContainer">
      <div className="tabsLine" style={{ marginRight: 20 }} />
      <div className="tabs">
        <button
          /*if*/
          className={active === "home" ? "active" : ""}
          onClick={() => onChange("home")}
        >
          {t('home').toUpperCase()}
        </button>
        <button
          className={active === "work" ? "active" : ""}
          onClick={() => onChange("work")}
        >
          {t('experience').toUpperCase()}
        </button>
        <button
          className={active === "projects" ? "active" : ""}
          onClick={() => onChange("projects")}
        >
          {t('projects').toUpperCase()}
        </button>
        <button
          className={active === "skills" ? "active" : ""}
          onClick={() => onChange("skills")}
        >
          {t('skills').toUpperCase()}
        </button>
        <button
          className={active === "languages" ? "active" : ""}
          onClick={() => onChange("languages")}
        >
          {t('languages').toUpperCase()}
        </button>
        <button
          className={active === "education" ? "active" : ""}
          onClick={() => onChange("education")}
        >
          {t('education').toUpperCase()}
        </button>
        <button
          className={active === "contact" ? "active" : ""}
          onClick={() => onChange("contact")}
        >
          {t('contact').toUpperCase()}
        </button>
      </div>
      <div className="tabsLine" style={{ marginLeft: 20 }} />
    </div>
  );
}

export default Tabs;
