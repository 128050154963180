import ProfileLink from "./ProfileLink";
import LanguageSwitcher from "./LanguageSwitcher";

function Footer({ basics }) {
  return (
    <div className="footer">
      <div>
        <LanguageSwitcher />
      </div>
      <div>
        {basics.profiles.map(function (item, index) {
          return <ProfileLink key={index} item={item} />;
        })}
        {basics.email && (
          <ProfileLink
            item={{ network: "Mail", url: "mailto:" + basics.email }}
          />
        )}
      </div>
    </div>
  );
}

export default Footer;
