import { useTranslation } from 'react-i18next';
import { createContext, useContext, useState } from "react";

const LanguageContext = createContext({});

export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [_language, _setLanguage] = useState("en");

  const value = {
    language: _language,
    setLanguage: (language) => {
      i18n.changeLanguage(language);
      _setLanguage(language);
    },
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
