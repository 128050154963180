import { useState } from "react";
import { useTranslation } from "react-i18next";

function Menu({ active, onChange }) {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);

    function handleShow() {
        setShow(!show);
    }

    function handleChange(section) {
        onChange(section);
        setShow(false);
    }

    return (
        <>
            <button className="menuButton" onClick={() => handleShow()}>
                {t('menu').toUpperCase()}
            </button>
            {show && (
                <div className="menuTabs tabs">
                    <button
                        className={active === "home" ? "active" : ""}
                        onClick={() => {
                            handleChange("home");
                        }}
                    >
                        {t('home').toUpperCase()}
                    </button>
                    <button
                        className={active === "work" ? "active" : ""}
                        onClick={() => handleChange("work")}
                    >
                        {t('experience').toUpperCase()}
                    </button>
                    <button
                        className={active === "projects" ? "active" : ""}
                        onClick={() => handleChange("projects")}
                    >
                        {t('projects').toUpperCase()}
                    </button>
                    <button
                        className={active === "skills" ? "active" : ""}
                        onClick={() => handleChange("skills")}
                    >
                        {t('skills').toUpperCase()}
                    </button>
                    <button
                        className={active === "languages" ? "active" : ""}
                        onClick={() => handleChange("languages")}
                    >
                        {t('languages').toUpperCase()}
                    </button>
                    <button
                        className={active === "education" ? "active" : ""}
                        onClick={() => handleChange("education")}
                    >
                        {t('education').toUpperCase()}
                    </button>
                    <button
                        className={active === "contact" ? "active" : ""}
                        onClick={() => handleChange("contact")}
                    >
                        {t('contact').toUpperCase()}
                    </button>
                </div>
            )}
        </>
    );
}

export default Menu;
